<template>
  <div class="login-screen modal-in">
    <f7-view url="/login" class="login">
      <div class="page login-screen-page">
        <div class="page-content login-screen-content">
          <div class="login-screen-title">
            <img src="../assets/login-logo.png" class="image" alt="" />
          </div>
          <form>
            <div class="list inline-labels">
              <ul>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input
                        type="text"
                        placeholder="请输入手机号"
                        v-model="phone"
                      />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="text" v-model="code" placeholder="验证码" />
                      <span class="input-clear-button"></span>
                    </div>
                    <div
                      :class="['item-after', this.canSend ? 'color-red' : '']"
                      @click="fetchCode"
                    >
                      {{ timeLabel }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </form>

          <div class="block-footer">
            如果未注册用户，登录即注册<br />
            <div class="row">
              <input type="checkbox" v-model="isAgree" />登录即代表同意<label
                class="label"
                @click="toProtocolPage"
                >用户协议</label
              >和<label class="label" @click="toProtocolPage">隐私政策</label>
            </div>
          </div>
          <div class="button-row">
            <div class="button button-fill button-large" @click="login">
              登录
            </div>
          </div>
          <div class="others">
            <div class="label">其他方式登录</div>
            <div class="icon">
              <img src="../assets/wechat.png" alt="" class="image" @click="loginByOpenHand()" />
            </div>
          </div>
        </div>
      </div>
    </f7-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: "",
      code: "",
      autoCheck: "",
      isAgree: false,
      timeLabel: "获取验证码",
      canSend: true,
      userList: [],
      showUserModel:false,
      authLogin:true,
      canClick: true // 用于控制点击
    };
  },
  mounted() {
    if(this.$f7route){
      // 确保 resh 是字符串类型并进行比较
      if (this.$f7route.query) {
        this.authLogin=false
      }
    }
    console.log(6666)
    this.$root.$children[0].eventCenter.$on(
      "agree",
      (isAgree) => (this.isAgree = isAgree)
    );
    this.$nextTick(() => {
      this.loginByOpenId(); // 确保页面渲染完成后再调用
    });
  },

  methods: {

    async loginByOpenHand(){
      this.loginByOpenId("show")
    },
    handleSelect(phone, xh) {
      this.handlePhoneSelect(phone, xh); // 调用 Vue 组件内的函数
    },
    async handlePhoneSelect(phone, xh){
      if (!this.canClick) return; // 如果不能点击则返回
      this.canClick = false; // 设置为不可点击
      // 2秒后允许再次点击
      setTimeout(() => {
        this.canClick = true; // 恢复为可点击
      }, 5000);
      const formData = new FormData();
      formData.append("phone", phone);
      formData.append("xh", xh);
      const response = await fetch(
        `/ShuKeManage/SignController/choiceUser`,
        {
          method: "post",
          body: formData,
        }
      );
      const jsonData = await response.json();
      this.$f7.dialog.close();
      if (jsonData.code === "0000") {
        this.$root.$children[0].isLogin = true;
        this.$root.$children[0].userInfo = {
          id: jsonData.data.orderUserId,
          ...jsonData.data,
          clientList: [
            ...jsonData.data.clientList,
          ],
        };
        if (jsonData.data.clientList.length === 0) {
          this.$f7.popup.open(".popup-alert");
          return;
        }
        const index = jsonData.data.clientList.findIndex((item) => {
          return Number(item.id) === Number(jsonData.data.orderUserId);
        });
        if(index===-1){
          const formData = new FormData();
          formData.append("clientId", jsonData.data.clientList[0].orderUserId);
          formData.append("userId", this.$root.$children[0].userInfo.xh);
          await fetch(
            `/ShuKeManage/SignController/updatePhoneUserClient`,
            {
              method: "post",
              body: formData,
            }
          );
        }
        this.$root.$children[0].selectedIndex = index === -1 ? 0 : index;
        this.$f7.loginScreen.close();
        return;
      }
      this.$f7.dialog.alert(jsonData.codeMsg, "");
    },
    async loginByOpenId(show){
      console.log(this.$root.$children[0].code)
      const formData = new FormData();
      formData.append("loginType", "wechat");
      formData.append("code", this.$root.$children[0].code);
      formData.append("isWechat", "public");
      formData.append("autoLogin", this.authLogin);
      const response = await fetch(
        `/ShuKeManage/SignController/loginByIphoneNew`,
        {
          method: "post",
          body: formData,
        }
      );
      this.authLogin=false
      const jsonData = await response.json();
      if (jsonData.code === "0000") {
        this.userList = jsonData.data; // 假设 jsonData.data 是一个手机号码数组
        this.showPhoneSelectionDialog(); // 调用函数显示选择框,
        return;
      }
      else if(jsonData.code === "200"){
        this.handlePhoneSelect(jsonData.data.phonenumber,jsonData.data.xh)
      }
      if(null!=show){
        this.$f7.dialog.alert(jsonData.codeMsg, "");
      }
    },
    showPhoneSelectionDialog() {
      const buttonElements = this.userList.map(item => {
        return `<button class="dialog-button" style="display: flex; justify-content: center; align-items: center; margin: 5px; padding: 10px; font-size: 16px; border: none; border-radius: 4px; background-color: #007aff; color: white; transition: all 0.2s;"
            data-phone="${item.phonenumber}"
            data-xh="${item.xh}"
            onmousedown="this.style.transform='scale(0.95)'; this.style.backgroundColor='#005bb5';"
            onmouseup="this.style.transform='scale(1)'; this.style.backgroundColor='#007aff';"
            onmouseleave="this.style.transform='scale(1)'; this.style.backgroundColor='#007aff';">
            ${item.phonenumber}
          </button>`;
      }).join('');

      const dialogContent = `
    <div style="position: relative; padding: 15px;">
      <button id="close-dialog" style="position: absolute; top: 10px; left: 110px; background: none; border: none; color: #007aff; font-size: 18px; cursor: pointer;">✖</button>
      <h4 style="margin: 10px 0; text-align: center;">选择登录手机</h4>
      <div style="display: flex; flex-direction: column; align-items: center; width: 100%;">
        ${buttonElements}
      </div>
    </div>
  `;

      const dialog = this.$f7.dialog.create({
        content: dialogContent,
        cssClass: 'custom-dialog',
        on: {
          opened: () => {
            // 绑定点击事件
            const buttons = document.querySelectorAll('.dialog-button');
            buttons.forEach(button => {
              button.addEventListener('click', () => {
                const phone = button.getAttribute('data-phone');
                const xh = button.getAttribute('data-xh');
                this.handlePhoneSelect(phone, xh); // 调用组件方法
              });
            });

            // 绑定关闭按钮事件
            const closeButton = document.getElementById('close-dialog');
            closeButton.addEventListener('click', () => {
              this.closeDialog(dialog); // 使用组件方法关闭对话框
            });
          }
        }
      });

      dialog.open();
    },
    closeDialog(dialog) {
      dialog.close();
    },
    async login() {
      if (this.phone === "" || !/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$f7.dialog.alert("请输入正确的手机号", "");
        return;
      }
      if (this.code === "") {
        this.$f7.dialog.alert("请输入验证码", "");
        return;
      }
      if (!this.isAgree) {
        this.$f7.dialog.alert("请同意用户协议与隐私政策", "");
        return;
      }
      const formData = new FormData();
      formData.append("verification_code", this.code);
      formData.append("phonenumber", this.phone);
      formData.append("code", this.$root.$children[0].code);
      formData.append("isWechat", "public");
      const response = await fetch(
        `/ShuKeManage/SignController/loginByIphone`,
        {
          method: "post",
          body: formData,
        }
      );
      const jsonData = await response.json();
      if (jsonData.state_code === "0000") {
        this.$root.$children[0].isLogin = true;
        this.$root.$children[0].userInfo = {
          id: jsonData.orderUserId,
          ...jsonData,
          clientList: [
            ...jsonData.clientList,
          ],
        };
        if (jsonData.clientList.length === 0) {
          this.$f7.popup.open(".popup-alert");
          return;
        }
        const index = jsonData.clientList.findIndex((item) => {
          return Number(item.id) === Number(jsonData.orderUserId);
        });
        if(index===-1){
          const formData = new FormData();
          formData.append("clientId", jsonData.clientList[0].orderUserId);
          formData.append("userId", this.$root.$children[0].userInfo.xh);
          await fetch(
            `/ShuKeManage/SignController/updatePhoneUserClient`,
            {
              method: "post",
              body: formData,
            }
          );
        }
        this.code=''
        this.$root.$children[0].selectedIndex = index === -1 ? 0 : index;
        this.$f7.loginScreen.close();
        return;
      }
      this.$f7.dialog.alert(jsonData.return_text, "");
    },
    async fetchCode() {
      if (this.phone === "" || !/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$f7.dialog.alert("请输入正确的手机号", "");
        return;
      }
      if (!this.canSend) {
        return;
      }
      try {
        const response = await fetch(
          `/ShuKeManage/SignController/loginCode?phonenumber=${this.phone}`
        );
        const jsonData = await response.json();
        console.log(jsonData);
        this.canSend = false;
        let time = 60;
        const interval = setInterval(() => {
          if (time === 0) {
            clearInterval(interval);
            this.canSend = true;
            this.timeLabel = "发送验证码";
            return;
          }
          this.timeLabel = `${time}s`;
          time -= 1;
        }, 1000);
      } catch (e) {
        this.$f7.dialog.alert("发送验证码失败", "");
        console.log(e);
      }
    },
    toProtocolPage() {
      this.$f7.views.get(".login").router.navigate({ url: "/ProtocolPage" });
    },
  },
};
</script>
<style scoped>
.login-screen-page .login-screen-content,
.login-screen > .view > .page .login-screen-content {
  margin-top: 100px;
  padding-top: 0;
  margin-bottom: 0;
  height: 100%;
}
.login-screen-page .login-screen-title {
  margin-top: 0;
}
.login-screen-title .image {
  width: 53px;
  height: 53px;
  border-radius: 10px;
}
.others {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 100px;
  left: 0;
}
.others .label {
  position: relative;
  font-size: 15px;
  width: 100%;
  text-align: center;
}
.others .label::before,
.others .label::after {
  content: "";
  width: 30%;
  position: absolute;
  top: 10px;
  height: 1px;
  background: #000;
}
.others .label::before {
  left: 20px;
}
.others .label::after {
  right: 20px;
}

.others .image {
  margin-top: 20px;
  width: 50px;
  height: 50px;
}
.list {
  margin: 0 17px;
}
.item-input:after {
  content: "";
  position: absolute;
  background-color: var(--f7-list-item-border-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}
.color-red {
  color: rgba(246, 13, 13, 1);
}
.button-row {
  margin: 0 25px;
}
.button-row .button {
  background: rgba(246, 13, 13, 1);
}
.block-footer {
  font-size: 10px;
  color: rgba(153, 153, 153, 1);
}
.block-footer .row {
  margin-top: 5px;
  justify-content: center;
  align-items: center;
}
.block-footer .label {
  color: rgba(89, 157, 240, 1);
}
</style>
