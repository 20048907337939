<template>
  <div id="app">
    <f7-app :params="f7params">
      <f7-views toolbar-through>
        <Login-page />
        <f7-view main>
          <Left-panel />
          <Store-page />
          <Popup-page :count="userInfo.clientList.length" />
        </f7-view>
      </f7-views>
    </f7-app>
  </div>
</template>

<script>
import Vue from "vue";
import LoginPage from "./LoginPage.vue";
import ProtocolPage from "./ProtocolPage.vue";
import NotificationPage from "./NotificationPage.vue";
import StorePage from "./StorePage.vue";
import StoreCenterPage from "./StoreCenterPage.vue";
import PopupPage from "./PopupPage.vue";

import LeftPanel from "./LeftPanel.vue";
export default {
  components: { LoginPage, StorePage, LeftPanel, PopupPage },
  name: "App",
  data() {
    return {
      eventCenter: new Vue(),
      isLogin: false,
      selectedIndex: 0,
      code: "",
      autoCheck:"true",
      userInfo: {
        clientList: [
          {
            addr: "上海市闵行区联航路1505弄1号楼1307室",
            clientjgname: "888888",
            clientname: "熟客收银培训", //门店名称
            clientsdid: "SH29096",
            clienttype: 1,
            createdate: 1664502074000,
            createuser: 1,
            cszpath:
              "https://www.shuke.shop/ShuKeManage/202210201344420238884.jpg",
            email: "1",
            employeeid: 4,
            enabled: true,
            grouptype: 15,
            id: 48, //门店商户id（也是认证号）
            imgCount: 0,
            isdeleted: false,
            lat: 31.076256,
            licencename: "上海红增信息技术有限公司", //商户名称
            lng: 121.517382,
            mtzpath:
              "https://www.shuke.shop/ShuKeManage/202210201344420238884.jpg", //商户的头像路径
            name: "收银培训",
            outPhone: "13918970013",
            phone: "15921285681",
            qtzpath:
              "https://www.shuke.shop/ShuKeManage/202211071732220650106.jpg", //背景图片路径
            reserved1: 3,
            reserved5: "20221030121", //商户号（商户编号也是这个，前面加上SK）
            reserved6: "24743852", //终端号
            state: 4,
            updatedate: 1686028967000,
            updateuser: 1,
            userId: "97",
            wechat: "1",
          }
        //   {
        //     addr: "1",
        //     clientjgname: "888888",
        //     clientname: "八奇公司", //门店名称
        //     clientsdid: "SH26814",
        //     clienttype: 1,
        //     createdate: 1687784641000,
        //     createuser: 1,
        //     email: "1",
        //     enabled: true,
        //     grouptype: 16,
        //     id: 234, //门店商户id（也是认证号）
        //     imgCount: 0,
        //     isdeleted: false,
        //     licencename: "八奇有限公司", //商户名称
        //     name: "1",
        //     outPhone: "123456789011",
        //     phone: "18315305212",
        //     reserved1: 3,
        //     reserved5: "20221106366", //商户号（商户编号也是这个，前面加上SK）
        //     reserved6: "20791756", //终端号
        //     state: 4,
        //     wechat: "1",
        //   },
        ],
      },
      f7params: {
        theme: "ios",
        routes: [
          {
            path: "/ProtocolPage",
            component: ProtocolPage,
          },
          {
            path: "/NotificationPage",
            component: NotificationPage,
          },
          {
            path: "/StoreCenterPage",
            component: StoreCenterPage,
          },
          {
            path: "/LoginPage",
            component: LoginPage,
          },
        ],
      },
    };
  },
  mounted() {
    const href = window.location.href;
    if (href.includes("code")) {
      const data = href.split("?")[1];
      const array = data.split("&");
      const codeString = array.filter((item) => item.includes("code"));
      this.code = codeString[0].split("=")[1];
    }
  },
  methods: {},
};
</script>

<style>
#panel-nested {
  width: 85vw;
}
.panel-backdrop.panel-backdrop-in {
  background: rgba(195, 195, 195, 0.6);
}
</style>
