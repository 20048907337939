import Vue from 'vue'
import Framework7 from "framework7/framework7.esm.bundle";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle";
import "framework7/css/framework7.bundle.css";
import "framework7-icons";

Framework7.use(Framework7Vue);

import App from './official-pages/App.vue'

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
}).$mount('#app')
