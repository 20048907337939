<style scoped>
.login-screen > .view > .page .page-content {
  margin: 0;
}
.button {
  width: 20%;
}
.disagree {
  background: rgba(139, 135, 136, 1);
}
.agree {
  background: rgba(250, 0, 13, 1);
}
</style>
<template>
  <div class="page">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="title">用户协议和隐私条款</div>
      </div>
    </div>
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <div class="button button-fill disagree" @click="handleClick(false)">
          不同意
        </div>
        <div class="button button-fill agree" @click="handleClick(true)">
          同意
        </div>
      </div>
    </div>
    <div class="page-content toolbar-fixed">
      <p>隐私权政策</p>
      【上海红增信息技术有限公司】（以下简称“熟客shuke”）尊重并保护所有使
      用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，熟客
      shuke会按照本隐私权政策的规定使用和披露您的个人信息。但熟客shuke将
      以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未
      征得您事先许可的情况下，熟客shuke不会将这些信息对外披露或向第三方
      提供。熟客shuke会不时更新本隐私权政策。您在同意熟客shuke服务使用协
      议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于熟客
      shuke服务使用协议不可分割的一部分。
      <br />
      一、 熟客shuke如何收集和使用您的个人信息 （一）为您提供在线门店管理功能 1)
      业务功能一：注册成为新用户
      在您注册熟客shuke帐号时，为了完成账号注册，您根据熟客shuke要求提供的个
      人的手机号；
      上述信息将存储于中华人民共和国境内。如需跨境传输，熟客shuke将会单独征
      得您的授权同意。 2) 业务功能二：定位 在您使用熟客shuke
      app进行配送服务时，熟客shuke自动获取您当前的地理位
      置，并且根据定位实时反馈给消费者，便于消费者清楚了解到配送的进度。 二、
      熟客shuke如何共享、转让、公开披露您的个人信息 （一）共享
      1）熟客shuke不会向任何无关第三方提供、出售、出租、分享或交易您的个人
      信息，除非事先得到您的许可，或该第三方和熟客shuke（含熟客shuke关联
      公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其
      以前能够访问的所有这些资料。 2)
      熟客shuke亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播
      您的个人信息。任何熟客shuke平台用户如从事上述活动，一经发现，熟客
      shuke有权立即终止与该用户的服务协议。 3)
      为服务用户的目的，熟客shuke可能通过使用您的个人信息，向您提供您感
      兴趣的信息，包括但不限于向您发出产品和服务信息，或者与熟客shuke合作
      伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事
      先同意）。
      <br />
      （二）转让
      熟客shuke不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      1、在获取明确同意的情况下转让：获得您的明确同意后，熟客shuke会向其他
      方转让您的个人信息；
      2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，熟客shuke会在
      要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则熟客
      shuke将要求该公司、组织重新向您征求授权同意。 （三）公开披露
      熟客shuke仅会在以下情况下，公开披露您的个人信息： 1、获得您明确同意后；
      2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的
      情况下，熟客shuke可能会公开披露您的个人信息。 三、
      熟客shuke如何保护您的个人信息
      （一）熟客shuke已使用符合业界标准的安全防护措施保护您提供的个人信息，
      防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。熟客
      shuke会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器
      与“服务”之间交换数据（如用户登录信息）时受 SSL 加密保护；熟客shuke同
      时对熟客shuke网站提供 https 安全浏览方式；熟客shuke会使用加密技术确
      保数据的保密性；熟客shuke会使用受信赖的保护机制防止数据遭到恶意攻击
      ；熟客shuke会部署访问控制机制，确保只有授权人员才可访问个人信息；
      以及熟客shuke会举办安全和隐私保护培训课程，加强员工对于保护个人信息
      重要性的认识。
      （二）熟客shuke会采取一切合理可行的措施，确保未收集无关的个人信息。
      熟客shuke只会在达成本政策所述目的所需的期限内保留您的个人信息，除非
      需要延长保留期或受到法律的允许。
      （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他熟客
      shuke用户的交流方式并未加密，熟客shuke强烈建议您不要通过此类方式发
      送个人信息。
      （四）互联网环境并非百分之百安全，熟客shuke将尽力确保或担保您发送
      给熟客shuke的任何信息的安全性。如果熟客shuke的物理、技术、或管理防
      护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致
      您的合法权益受损，熟客shuke将承担相应的法律责任。
      （五）在不幸发生个人信息安全事件后，熟客shuke将按照法律法规的要求，
      及时向您告知：安全事件的基本情况和可能的影响、熟客shuke已采取或将
      要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
      熟客shuke将及时将事件相关情况以邮件、信函、电话、推送通知等方式告
      知您，难以逐一告知个人信息主体时，熟客shuke会采取合理、有效的方式发
      布公告。
      同时，熟客shuke还将按照监管部门要求，主动上报个人信息安全事件的处置 情况。
      四、 熟客shuke如何使用Cookie和同类技术 1)
      在您未拒绝接受cookies的情况下，熟客shuke会在您的设备设定或取用cookies
      ，以便您能登录或使用依赖于cookies的熟客shuke平台服务或功能。熟客shuke
      使用cookies可为您提供更加周到的个性化服务，包括推广服务。 2)
      您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝
      接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于
      cookies的熟客shuke网络服务或功能。 3)
      通过熟客shuke所设cookies所取得的有关信息，将适用本政策。
      <br />
      五、 熟客shuke如何使用Cookie和同类技术 a)
      熟客shuke帐号均有安全保护功能，请妥善保管您的用户名及密码信息。熟
      客shuke将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥
      用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完
      善的安全措施”。 b)
      在使用熟客shuke网络服务进行网上交易时，您不可避免的要向交易对方或
      潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保
      护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息
      泄密，尤其是熟客shuke用户名及密码发生泄露，请您立即联络熟客shuke客服
      ，以便熟客shuke采取相应措施。 六、 熟客shuke如何处理儿童的个人信息
      熟客shuke的产品、网站和服务主要面向成人。如果没有父母或监护人的同意
      ，儿童不得创建自己的用户账户。
      对于经父母同意而收集儿童个人信息的情况，熟客shuke只会在受到法律允许、
      父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
      尽管当地法律和习俗对儿童的定义不同，但熟客shuke将不满 14 周岁的任何人
      均视为儿童。
      如果熟客shuke发现自己在未事先获得可证实的父母同意的情况下收集了儿童
      的个人信息，则会设法尽快删除相关数据。
      <br />
      七、您的个人信息如何在全球范围转移
      原则上，熟客shuke在中华人民共和国境内收集和产生的个人信息，将存储在
      中华人民共和国境内。
      由于熟客shuke通过遍布全球的资源和服务器提供产品或服务，这意味着，在获
      得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家
      /地区的境外管辖区，或者受到来自这些管辖区的访问。
      此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下
      ，熟客shuke会确保您的个人信息得到在中华人民共和国境内足够同等的保护。
      例如，熟客shuke会请求您对跨境转移个人信息的同意，或者在跨境数据转移之
      前实施数据去标识化等安全举措。 八、本隐私权政策如何更新
      熟客shuke可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何
      更新将以标注更新时间的方式公布在熟客shuke网站上，除法律法规或监管规定
      另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您
      在隐私权政策调整或变更后继续使用熟客shuke提供的任一服务或访问熟客
      shuke相关网站的，熟客shuke相信这代表您已充分阅读、理解并接受修改后的
      隐私权政策并受其约束。 九、 如何联系我们
      如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：
      电话：【 021-62215253或13524773361 】一般情况下，我们将在三十天内回复。
      <br />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleClick(isAgree) {
      console.log(isAgree);
      this.$root.$children[0].eventCenter.$emit("agree", isAgree);
      this.$f7.views.get(".login").router.back();
    },
  },
};
</script>