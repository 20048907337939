<style scoped>
.block-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item-media .image {
  width: 24px;
  height: 24px;
}
.item-title {
  font-size: 15px;
  font-weight: 500;
}
.label {
  color: rgba(16, 15, 15, 1);
  font-size: 10px;
  margin-left: 5px;
  font-weight: 200;
}
</style>
<template>
  <div class="page">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="title">消息通知</div>
      </div>
    </div>
    <div class="page-content">
      <div class="block-title">
        <img src="../assets/voice.png" class="image" />
        语音播报
      </div>
      <div class="list">
        <ul>
          <li class="item-content">
            <div class="item-media">
              <img src="../assets/pos.png" class="image" />
            </div>
            <div class="item-inner">
              <div class="item-title">
                门店收款工具<label class="label">门店POS机器、扫码盒</label>
              </div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" value="yes" /><i
                    class="toggle-icon"
                  ></i>
                </label>
              </div>
            </div>
          </li>
          <li class="item-content">
            <div class="item-media">
              <img src="../assets/qrcode-image.png" class="image" />
            </div>
            <div class="item-inner">
              <div class="item-title">二维码收款</div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" value="yes" /><i
                    class="toggle-icon"
                  ></i>
                </label>
              </div>
            </div>
          </li>
          <li class="item-content">
            <div class="item-media">
              <img src="../assets/scan.png" class="image" />
            </div>
            <div class="item-inner">
              <div class="item-title">控制台扫一扫收款</div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" value="yes" /><i
                    class="toggle-icon"
                  ></i>
                </label>
              </div>
            </div>
          </li>
          <li class="item-content">
            <div class="item-media">
              <img src="../assets/login-logo.png" class="image" />
            </div>
            <div class="item-inner">
              <div class="item-title">
                熟客电商收款<label class="label">熟客shukeAPP</label>
              </div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" value="yes" /><i
                    class="toggle-icon"
                  ></i>
                </label>
              </div>
            </div>
          </li>
          <li class="item-content">
            <div class="item-media">
              <img src="../assets/mini-program.png" class="image" />
            </div>
            <div class="item-inner">
              <div class="item-title">
                小程序订单<label class="label">微信支付宝小程序</label>
              </div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" value="yes" /><i
                    class="toggle-icon"
                  ></i>
                </label>
              </div>
            </div>
          </li>
          <li class="item-content">
            <div class="item-media">
              <img src="../assets/chat.png" class="image" />
            </div>
            <div class="item-inner">
              <div class="item-title">聊天消息未回复提醒</div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" value="yes" /><i
                    class="toggle-icon"
                  ></i>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="block-title">公众号消息</div>
      <div class="list">
        <ul>
          <li class="item-content">
            <div class="item-inner">
              <div class="item-title">微信公众号</div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" v-model="wxInfo" />
                  <i class="toggle-icon"></i>
                </label>
              </div>
            </div>
          </li>
          <li class="item-content">
            <div class="item-inner">
              <div class="item-title">支付宝公众号</div>
              <div class="item-after">
                <label class="toggle toggle-init">
                  <input type="checkbox" name="toggle" v-model="zfbInfo" />
                  <i class="toggle-icon"></i>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wxInfo: 0,
      zfbInfo: 0,
      needUpdate: false,
    };
  },
  watch: {
    wxInfo() {
      if (this.needUpdate) {
        this.updateNotification();
      }
    },
    zfbInfo() {
      if (this.needUpdate) {
        this.updateNotification();
      }
    },
  },
  computed: {
    clientInfo() {
      return (
        this.$root.$children[0].userInfo.clientList[
          this.$root.$children[0].selectedIndex
        ] || { id: "" }
      );
    },
  },
  mounted() {
    this.fetchNotificationData();
  },
  methods: {
    async updateNotification() {
      const formData = new FormData();
      formData.append("id", this.clientInfo.id);
      formData.append("wxInfo", this.wxInfo ? 1 : 0);
      formData.append("zfbInfo", this.zfbInfo ? 1 : 0);
      const response = await fetch(
        `/ShuKeManage/MstClientController/updateBroadcast`,
        {
          method: "post",
          body: formData,
        }
      );
      const jsonData = await response.json();
      if (jsonData.code !== "0000") {
        return;
      }
    },
    async fetchNotificationData() {
      const formData = new FormData();
      formData.append("id", this.clientInfo.id);
      const response = await fetch(
        `/ShuKeManage/MstClientController/queryBroadcast`,
        {
          method: "post",
          body: formData,
        }
      );
      const jsonData = await response.json();
      if (jsonData.code !== "0000") {
        return;
      }
      const { wxInfo, zfbInfo } = jsonData.data;
      this.wxInfo = wxInfo === 1;
      this.zfbInfo = zfbInfo === 1;
      this.needUpdate = true;
      console.log(jsonData.data);
    },
  },
};
</script>
