<style scoped>
.popup-alert {
  background: rgba(119, 119, 119, 0.6);
}
.popup-alert .container {
  margin: 150px 20px;
}
.container .logo {
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}
.container .logo .image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.container .logo .icon {
  position: absolute;
  right: 30px;
  top: -30px;
  color: #fff;
}
.container .title {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}
.popup-alert .open-button {
  background: rgba(235, 103, 103, 1);
  height: 44px;
  line-height: 44px;
  color: #fff;
}
.alert {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 20px;
}
</style>
<template>
  <div class="popup popup-alert">
    <div class="container">
      <div class="logo">
        <img src="../assets/login-logo.png" class="image" />
        <i class="f7-icons icon" @click="$f7.popup.close('.popup-alert')"
          >multiply</i
        >
      </div>
      <div class="title">看到精彩门店</div>
      <div class="alert" v-show="count !== 0">
        您的手机未绑定任何商家请下载熟客APP申请注册为商家
      </div>
      <div class="button button-fill open-button">请在熟客APP中打开</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["count"],
};
</script>