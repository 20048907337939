<style scoped>
.page-content {
  background: #fff;
}
.store-list {
  margin-top: 10px;
  padding-top: 15px;
  position: relative;
}
.store-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 8px;
  right: 8px;
  height: 1px;
  background: rgba(229, 229, 229, 1);
}
.add-button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  margin-top: 22px;
}
.add-button {
  display: flex;
  align-items: center;
  color: rgba(235, 103, 103, 1);
  border: 1px solid rgba(235, 103, 103, 1);
  width: 130px;
}

.defalu-button {
  margin-left: 15px;
  width: 70px;
  height: 24px;
  line-height: 24px;
}
.store-item {
  display: flex;
  align-items: center;
  padding: 0 10px 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(13, 12, 12, 1);
}
.store-container {
  position: relative;
  margin-top: 7px;
}
.store-container::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 8px;
  right: 8px;
  height: 1px;
  background: rgba(229, 229, 229, 1);
}
.store-item .label {
  width: 20%;
}
.store-item .value {
  display: flex;
  align-items: baseline;
  width: 80%;
}
.store-item:last-child .label {
  width: 30%;
}
.value .button {
  width: 70px;
  height: 24px;
  line-height: 24px;
  margin-left: auto;
}
.button.button-fill {
  background: rgba(243, 17, 17, 1);
}
.button .icon {
  font-size: 14px;
  color: #000;
}
.more-button {
  text-align: end;
}
</style>
<template>
  <div class="page">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="title">商家账户中心</div>
      </div>
    </div>
    <div class="page-content">
      <div class="add-button-container">
        <div class="add-button button" @click="$f7.popup.open('.popup-alert')">
          <img src="../assets/plus.png" alt="" srcset="" />
          关联新商家
        </div>
      </div>
      <div class="store-list">
        <div
          class="store-container"
          v-for="(item, index) in clientList"
          :key="index"
        >
          <div class="defalu-button button-fill button" v-show="index === 0">
            默认
          </div>
          <div class="store-item">
            <div class="label">门店名称：</div>
            <div class="value">
              {{ item.clientname }}
              <div class="button button-fill" @click="selectStore(index,item.id)">
                进入
              </div>
            </div>
          </div>
          <div class="store-item">
            <div class="label">商户名称：</div>
            <div class="value">{{ item.licencename }}</div>
          </div>
          <div class="store-item">
            <div class="label">商户编号：</div>
            <div class="value">{{ item.reserved5 }}</div>
          </div>
          <div class="store-item">
            <div class="label">认证号：</div>
            <div class="value">{{ item.id }}</div>
          </div>
          <div class="store-item">
            <div class="label">店员账号管理</div>
            <div class="value">
              <div
                class="button more-button"
                @click="$f7.popup.open('.popup-alert')"
              >
                <i class="f7-icons icon">chevron_right_2</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clientList: [],
    };
  },
  computed: {
    clientInfo() {
      return (
        this.$root.$children[0].userInfo.clientList[
          this.$root.$children[0].selectedIndex
        ] || { id: "" }
      );
    },
  },
  mounted() {
    this.clientList = this.$root.$children[0].userInfo.clientList;
  },
  methods: {
    async selectStore(index,clientId) {
      const formData = new FormData();
      formData.append("clientId", clientId);
      formData.append("userId", this.$root.$children[0].userInfo.xh);
      const response = await fetch(`/ShuKeManage/SignController/updatePhoneUserClient`, {
        method: "post",
        body: formData,
      });
      const jsonData = await response.json();
      if (jsonData.code !== "0000") {
        return;
      }
      this.$root.$children[0].selectedIndex = index;
      this.$f7.views.main.router.back();
    },
  },
};
</script>
